import React from 'react';
import { Layout, SEO } from 'components/common';
import { Implantat } from 'components/landing/Implantat';

export default () => (
  <Layout>
    <SEO />
    <Implantat/>
  </Layout>
);
