import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { FormattedMessage } from "gatsby-plugin-intl"
import { Header } from 'components/theme';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const Implantat = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <StaticQuery
      query={graphql`
        query ImplantatQuery {
          file(relativePath: { eq: "kereszty_implantat.png" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          } 
        }
      `}
      render={data => (
        <Wrapper>
            <Header />
            <SkillsWrapper as={Container}>
              <Thumbnail>
                <Img
                  fluid={data.file.childImageSharp.fluid}
                  alt="dr. Kereszty Praxis, Implantat"
                />
              </Thumbnail>
              <Details theme={theme}>
                <div>
                  <h2><FormattedMessage id="implantat.title" /></h2> 
                  <p><FormattedMessage id="implantat.text.1" /></p>
                  <p><FormattedMessage id="implantat.text.2" /></p>
                  <p><FormattedMessage id="implantat.text.3" /></p>
                  <p><FormattedMessage id="implantat.text.4" /></p>
                </div>
                
              </Details>
            </SkillsWrapper>
          </Wrapper>
      )}
    />
  )
};
